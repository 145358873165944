<template>
    <!-- Modal -->
    <Form ref="resetForm" v-slot="{ errors }" :validation-schema="schema"
          @submit="$emit('formAction', {url:$config.Company.CompanyEndPoint, modalId: 'showModal'}, data.inputFormData)">
        <div class="modal-body">
            <div class="row">
                <div class="col-6 mb-3">
                    <div class="form-group">
                        <label class="form-control-label w-100 text-start"> Company Name</label>
                        <Field type="text" class="form-control" placeholder="Name" v-model="data.inputFormData.company_name"
                               :class="{'border-danger': errors.company_name}" name="company_name"/>
                        <span class="text-danger" v-if="errors.company_name">{{ errors.company_name }}</span>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Name</label>
                        <Field type="text" class="form-control" placeholder="Name" v-model="data.inputFormData.name"
                               :class="{'border-danger': errors.name}" name="name"/>
                        <span class="text-danger" v-if="errors.name">{{ errors.name }}</span>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Email</label>
                        <Field type="email" class="form-control" placeholder="Email" v-model="data.inputFormData.email"
                               :class="{'border-danger': errors.email}" name="email"/>
                        <span class="text-danger" v-if="errors.email">{{ errors.email }}</span>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Mobile</label>
                        <Field type="tel" class="form-control" placeholder="Mobile" v-model="data.inputFormData.mobile"
                               :class="{'border-danger': errors.mobile}" name="mobile"/>
                        <span class="text-danger" v-if="errors.mobile">{{ errors.mobile }}</span>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Address</label>
                        <textarea class="form-control" rows="2" v-model="data.inputFormData.address"></textarea>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Attach Logo</label>
                        <input type="file" class="form-control" @change="$emit('formFile', $event, 'logo')">
                    </div>
                    <div>
                        <img :src="data.imageUrl ? data.imageUrl : data.inputFormData.logo"
                              alt="" class="img-fluid site-logo">
                    </div>
                </div>
                <div class="col-6 mb-3" v-if="data.superAdmin">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Subscription Type</label>
                        <Field as="select" class="form-control" v-model="data.inputFormData.type_id"
                                :class="{'border-danger': errors.type_id}" name="type_id">
                            <option selected="">Subscription Type</option>
                            <option v-for="item in subs_types()" :value="item.id"> {{ item.type }} </option>
                        </Field>
                        <span class="text-danger" v-if="errors.type_id">{{ errors.type_id }}</span>
                    </div>
                </div>
                <div class="col-6 mb-3">
                    <div class="form-group mb-3">
                        <label class="form-control-label w-100 text-start">Expire Date</label>
                        <input type="date" class="form-control" name="valid-from" min=" " max=" " v-model="data.inputFormData.expired_at">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="submit" class="btn btn-primary">Submit</button>
        </div>
    </Form>

    <!-- /Modal -->
</template>

<script>
    import HelperFunction  from "@/common/helpers";
    import { watch, onMounted } from "vue"
    import {useStore} from "vuex";
    import {Field, Form} from "vee-validate";
    import * as Yup from "yup";

    export default {
        name: "UpdateCompanyForm",
        components: { Form, Field},
        props: {
            formData: {
                type: Object,
                required: false
            },
            imageUrl: {
                type: String,
                required: false
            },
        },
        emits: ['formAction', 'formFile'],
        setup(props) {
            const { data, resetForm } = HelperFunction();
            const store = useStore()
            data.superAdmin = store.getters.getProfile.superAdmin
            data.setURL = vueConfig.Company.CompanyEndPoint
            // FOR FORM DATA
            watch(() => props.formData.inputFormData, (value) => {
                if (value !== undefined) {
                    data.inputFormData = value;
                }
            }, {immediate: true});
            // FOR IMAGE URL
            watch(() => props.imageUrl, (value) => {
                if (value !== undefined) {
                    data.imageUrl = value;
                }
            }, {immediate: true});

            // CALL GETTERS
            function subs_types() {
                return store.getters.getSubscriptionTypes;
            }

            //VALIDATION
            const schema = Yup.object().shape({
                company_name: Yup.string().required(),
                type_id: data.superAdmin ? Yup.string().required() : '',
                name: Yup.string().required(),
                email: Yup.string().required(),
                mobile: Yup.string().required(),
            });

            return {
                data, subs_types, resetForm, schema
            }
        }
    }
</script>

<style scoped>

</style>
